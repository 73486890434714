<style>
.c-box {
	background-color: #222736 !important;
}
</style>

<script>
import {Carousel, Slide} from "vue-carousel";

export default {
	components: {
		// eslint-disable-next-line vue/no-unused-components
		Carousel,
		// eslint-disable-next-line vue/no-unused-components
		Slide,
		// eslint-disable-next-line vue/no-unused-components
		Subscription: () => import('@/router/views/store/Subscription'),
		Navbar: () => import('@/router/components/Navbar'),
		Footer: () => import('@/router/components/Footer'),
	},
	data() {
		return {
			start: "",
			end: "",
			interval: "",
			days: "",
			minutes: "",
			hours: "",
			seconds: "",
			starttime: "Jan 4, 2017 00:00:00",
			endtime: "May 10, 2021 00:00:00",
			stat_servers: null,
			stat_users: null,
			stat_online: null,
			stat_error: false,
			easterEgg: false
		};
	},
	created() {
		window.addEventListener("scroll", this.windowScroll);
		//this.requestStats();
		if(window.location.hostname === 'cftools.bayern') {
			this.easterEgg = true;
		}

		if(this.easterEgg) {
			document.body.style.cursor = "url('/bv.svg'), auto";
		}
	},
	destroyed() {
		window.removeEventListener("scroll", this.windowScroll);
	},
	mounted() {
		this.start = new Date(this.starttime).getTime();
		this.end = new Date(this.endtime).getTime();
		// Update the count down every 1 second
		this.timerCount(this.start, this.end);
		this.interval = setInterval(() => {
			this.timerCount(this.start, this.end);
		}, 1000);

		// Easter egg
		if(this.easterEgg) {
			setTimeout(() => {
				this.playEasterEgg();
			}, 500);
		}
	},
	methods: {
		async playEasterEgg() {
			this.$refs.easterEggAudio.volume = 0.0020;
			this.$refs.easterEggAudio.loop = true;
			let playResult = this.$refs.easterEggAudio.play();
			playResult.then(() => {}).catch((error) => {
				setTimeout(() => {
					this.playEasterEgg();
				}, 100);
			});
		},
		onMenuClick(event) {
			event.preventDefault();
			const nextEl = event.target.nextSibling;
			if (nextEl && !nextEl.classList.contains("show")) {
				const parentEl = event.target.parentNode;
				if (parentEl) {
					parentEl.classList.remove("show");
				}
				nextEl.classList.add("show");
			} else if (nextEl) {
				nextEl.classList.remove("show");
			}
			return false;
		},
		requestStats: function () {
			fetch('https://api.cftools.cloud/app/v1/stats').then(response => {
				return response.json();
			}).then(data => {
				this.stat_users = data.users.total;
				this.stat_online = data.users.playing;
				this.stat_servers = data.servers.total;
				// eslint-disable-next-line no-unused-vars
			}).catch(error => {
				// Fallback stats 29.10.2022
				this.stat_error = true;
				this.stat_servers = 3100;
				this.stat_users = 2350000;
				this.stat_online = 17000;
			})
		},
		timerCount: function (start, end) {
			// Get todays date and time
			var now = new Date().getTime();

			// Find the distance between now an the count down date
			var distance = start - now;
			var passTime = end - now;

			if (distance < 0 && passTime < 0) {
				clearInterval(this.interval);
				return;
			} else if (distance < 0 && passTime > 0) {
				this.calcTime(passTime);
			} else if (distance > 0 && passTime > 0) {
				this.calcTime(distance);
			}
		},
		calcTime: function (dist) {
			// Time calculations for days, hours, minutes and seconds
			this.days = Math.floor(dist / (1000 * 60 * 60 * 24));
			this.hours = Math.floor(
					(dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
			);
			this.minutes = Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60));
			this.seconds = Math.floor((dist % (1000 * 60)) / 1000);
		},
		/**
		 * Window scroll method
		 */
		windowScroll() {
			const navbar = document.getElementById("navbar");
			if (
					document.body.scrollTop >= 50 ||
					document.documentElement.scrollTop >= 50
			) {
				navbar.classList.add("nav-sticky");
			} else {
				navbar.classList.remove("nav-sticky");
			}
		},
		/**
		 * Toggle menu
		 */
		toggleMenu() {
			document.getElementById("topnav-menu-content").classList.toggle("show");
		},
		nextSlide() {
			this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
		},
		prevSlide() {
			this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
		}
	}
};
</script>

<template>
	<div>
		<template v-if="easterEgg">
			<div style="display: none;">
				<audio
						ref="easterEggAudio"
						src="/bv.mp3"
						preload
				></audio>
			</div>
		</template>
		<Navbar :isHomepage="true"/>

		<div v-scroll-spy>
			<!-- hero section start -->
			<section class="section hero-section bg-ico-hero" id="home">
				<!--<div class="bg-overlay bg-cftools"></div>-->
				<div class="container">
					<div class="row align-items-center">
						<div class="col-lg-6">
							<div class="text-white-50">
								<h1
										class="text-white font-weight-semibold mb-3 hero-title"
								>
									CFTools Cloud<br>
									<small style="font-size: 69%;">Manage & control your game servers</small>
								</h1>
								<p
										class="font-size-14"
								>
									The most sophisticated platform for your game servers. Scalable, flexible and highly available.
									Reliably powering the largest gaming communities, supported by the cloud.
								</p>

								<div class="button-items mt-4">
									<a href="javascript: void(0);" v-scroll-to="'#games'" class="btn btn-success mr-2"
										 style="border-radius: 0;">Supported games</a>
									<a href="javascript: void(0);" v-scroll-to="'#faqs'" class="btn btn-light ml-2"
										 style="border-radius: 0;">How it works</a>
								</div>
							</div>
						</div>
					</div>
					<!-- end row -->
				</div>
				<!-- end container -->
			</section>
			<!-- hero section end -->
			<!-- currency price section start -->
			<!--
			<section class="section bg-white p-0" v-if="stat_error !== true">
				<div class="container">
					<div class="currency-price">
						<div class="row">
							<div class="col-md-4">
								<div class="card border border-light shadow-lg c-box">
									<div class="card-body">
										<div class="media">
											<div class="avatar-xs mr-3">
												<span
														class="avatar-title rounded-circle bg-soft-warning text-warning font-size-18"
												>
													<i class="fas fa-server"></i>
												</span>
											</div>
											<div class="media-body">
												<p class="text-muted">Registered servers</p>
												<h3 v-if="stat_servers == null">
													<img src="@/assets/images/statloader.svg" height="25px"/>
												</h3>
												<h3 v-else>
													{{ stat_servers.toLocaleString() }}
												</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="card border border-light shadow-lg c-box">
									<div class="card-body">
										<div class="media">
											<div class="avatar-xs mr-3">
												<span
														class="avatar-title rounded-circle bg-soft-primary text-primary font-size-18"
												>
													<i class="fas fa-user"></i>
												</span>
											</div>
											<div class="media-body">
												<p class="text-muted">Users</p>
												<h3 v-if="stat_users == null">
													<img src="@/assets/images/statloader.svg" height="25px"/>
												</h3>
												<h3 v-else>
													{{ stat_users.toLocaleString() }}
												</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="col-md-4">
								<div class="card border border-light shadow-lg c-box">
									<div class="card-body">
										<div class="media">
											<div class="avatar-xs mr-3">
												<span
														class="avatar-title rounded-circle bg-soft-info text-info font-size-18"
												>
													<i class="fas fa-users"></i>
												</span>
											</div>
											<div class="media-body">
												<p class="text-muted">Currently monitored players</p>
												<h3 v-if="stat_online == null">
													<img src="@/assets/images/statloader.svg" height="25px"/>
												</h3>
												<h3 v-else>
													{{ stat_online.toLocaleString() }}
												</h3>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			-->
			<!-- curreny price section end -->
			<!-- about section start -->
			<section class="section pt-4 bg-white" id="about">
				<div class="container">
					<div class="row pt-4">
						<div class="col-lg-12">
							<div class="text-center mb-5">
								<div class="small-title">About us</div>
								<h4>CFTools Cloud</h4>
							</div>
						</div>
					</div>
					<div class="row align-items-center">
						<div class="col-lg-5">
							<div class="text-muted">
								<h4>Software for game server operators</h4>
								<p>
									CFTools Cloud is the next evolution in game server administration. Cloud powered, highly flexible and
									easily adaptable. CFTools Cloud is here to save you valuable time in your day to day admin operations
									and enable you to fully take control of your game server.
								</p>

								<div class="button-items">
									<a href="javascript: void(0);" v-scroll-to="'#games'" class="btn btn-success"
										 style="border-radius: 0;">Supported games</a>
									<a href="javascript: void(0);" v-scroll-to="'#features'" class="btn btn-light ml-2"
										 style="border-radius: 0;">How it works</a>
								</div>

								<!-- Key stats, max 3 -->
								<!--
								<div class="row mt-4">
									<div class="col-lg-4 col-6">
										<div class="mt-4">
											<h4>value</h4>
											<p>description</p>
										</div>
									</div>
								</div>
								-->
							</div>
						</div>

						<div class="col-lg-6 ml-auto">
							<div class="mt-4 mt-lg-0">
								<div class="row">
									<div class="col-sm-6">
										<div class="card border border-light shadow-lg c-box">
											<div class="card-body">
												<div class="mb-3">
													<i class="fas fa-code h2 text-success"></i>
												</div>
												<h5>API</h5>
												<p class="text-muted mb-0">Using the CFTools API you can get all your information and have
													custom integrations.</p>
											</div>
											<!--
											// Waiting for API docs
											<div class="card-footer bg-transparent border-top text-center">
												<a href="#" class="text-primary">Learn more</a>
											</div>
											-->
										</div>
									</div>
									<div class="col-sm-6">
										<div class="card border mt-lg-5 border-light shadow-lg c-box">
											<div class="card-body">
												<div class="mb-3">
													<i class="fas fa-comments h2 text-primary"></i>
												</div>
												<h5>Active community</h5>
												<p class="text-muted mb-0">Our community grows day by day. Visit us and check how it is to be a
													part of our family.</p>
											</div>
											<div class="card-footer bg-transparent border-top text-center">
												<a href="https://discord.com/invite/k7Zdw6cXSH" class="text-primary">Join our <i
														class="fab fa-discord"></i> Discord</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- end row -->
					<div class="row justify-content-end mt-5">
						<div class="col-lg-6 mr-auto"> <!-- ml-auto -->
							<div class="mt-4 mt-lg-0">
								<div class="row">
									<div class="col-sm-6">
										<div class="card border mt-lg-2 border-light shadow-lg c-box">
											<div class="card-body">
												<div class="mb-3">
													<i class="fas fa-user-check h2 text-info"></i>
												</div>
												<h5>Experienced</h5>
												<p class="text-muted mb-0">
													We are maintaining game servers since 2017 and have in-depth experience with large and small
													operations.
												</p>
											</div>
										</div>
									</div>
									<div class="col-sm-6">
										<div class="card border mt-lg-n5 border-light shadow-lg c-box">
											<div class="card-body">
												<div class="mb-3">
													<i class="fas fa-user-friends h2 text-warning"></i>
												</div>
												<h5>We are here for you</h5>
												<p class="text-muted mb-0">
													Individually and with experience, so that you can concentrate on your game server operations.
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<!--
						<div class="col-lg-5 col-sm-12 justify-content-end">
							<div class="text-muted text-right">
								<h4>Our expertise for your project</h4>
								<p>
									You need professional help with choosing the best hardware or setting up and maintaining your systems?<br>
									We can help you in every aspect.
								</p>

								<div class="button-items">
									<a href="mailto:inquiry@cftools.software?subject=Consulting%20and%20support%20inquiry" class="btn btn-primary" style="border-radius: 0;">
										<i class="fas fa-envelope-open-text"></i>
										Contact us by e-mail for a quote
									</a>
								</div>
							</div>
						</div>
						-->
						<div class="col-lg-5 col-sm-12 justify-content-end">
							<div class="text-muted text-right">
								<h4>Advanced tracking</h4>
								<p>
									We are tracking all statistics and metrics for you to optimize your game servers.<br>
									Never operate in the data abyss again.
								</p>
							</div>
						</div>
					</div>


					<!--
					 <hr class="my-5" />
					<span class="text-center">
						<h5>Proudly partnered with</h5>
					</span>
					<a href="https://ovh.com" target="_blank">
						<img
								src="https://www.ovh.com/world/images/newLogos/logo-ovh.png"
								alt="ovhcloud-logo"
								class="mx-auto img-fluid d-block">
					</a>
					-->

					<!-- feature customers -->

					<!--<hr class="my-5" />-->
					<div class="mt-5">
						<span class="text-center">
							<h4>Our Partners</h4>
						</span>
					</div>
					<div class="row">
						<div class="col-lg-12">
							<carousel
									class="clients-carousel"
									id="clients-carousel"

									:perPage="1"
									:paginationEnabled="false"
							>
				  <!--:perPageCustom="[[480, 2], [768, 4]]"-->
								<slide class="align-self-center">
									<div class="item">
										<div class="client-images">
											<a href="https://partner.ovhcloud.com/en/partner/cftools-software-ug-haftungsbeschrankt/" target="_blank">
												<img
														src="/logo-ovh.png"
														alt="client-img"
														class="mx-auto img-fluid d-block"
														style="max-height: 240px !important; opacity: 1.0 !important;"
												/>
											</a>
										</div>
									</div>
								</slide>
							</carousel>
						</div>
					</div>

				</div>

				<!-- end container -->
			</section>


			<!-- Features start -->
			<section class="section" id="features">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="text-center mb-5">
								<div class="small-title">Features</div>
								<h4>CFTools Cloud</h4>
							</div>
						</div>
					</div>
					<!-- end row -->

					<div class="row">
						<!-- RCon -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-link h2 text-primary"></i>
									</div>
									<h5>Available 24/7</h5>
									<p class="text-muted mb-0">
										CFTools Cloud is always connected with your game server.
									</p>
								</div>
							</div>
						</div>

						<!-- RCon protocol -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-ethernet h2 text-primary"></i>
									</div>
									<h5>RCon protocol</h5>
									<p class="text-muted mb-0">
										We track all your RCon logs for you. Easily searchable and accurate.
									</p>
								</div>
							</div>
						</div>

						<!-- Server security -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-shield-alt h2 text-primary"></i>
									</div>
									<h5>Server security</h5>
									<p class="text-muted mb-0">
										Using the server security module you are in total control on who can join your servers. Be it Steam
										VAC bans or a private profile.
									</p>
								</div>
							</div>
						</div>

						<!-- Whitelist -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-clipboard-list h2 text-primary"></i>
									</div>
									<h5>Whitelist</h5>
									<p class="text-muted mb-0">
										Only want approved players to join? No problem, using our whitelist feature you can restrict access
										easily.
									</p>
								</div>
							</div>
						</div>

						<!-- filters -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-filter h2 text-primary"></i>
									</div>
									<h5>Chat and name filters</h5>
									<p class="text-muted mb-0">
										Deploy chat and name filter rules for your game server to keep everything civil.
									</p>
								</div>
							</div>
						</div>

						<!-- Audit protocol -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-scroll h2 text-primary"></i>
									</div>
									<h5>Audit protocol</h5>
									<p class="text-muted mb-0">
										You think one of your admins is up to something? Our audit protocol is temper proof and shows
										exactly what your admins are doing.
									</p>
								</div>
							</div>
						</div>

						<!-- Server logs -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-list h2 text-primary"></i>
									</div>
									<h5>Server logs</h5>
									<p class="text-muted mb-0">
										CFTools Cloud logs everything that happens on your game server. Searchable by the second.
									</p>
								</div>
							</div>
						</div>

						<!-- Banlists -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-user-times h2 text-primary"></i>
									</div>
									<h5>Ban lists</h5>
									<p class="text-muted mb-0">
										Want to keep out players entirely? Use our ban lists. With our evidence upload, you will never have
										to doubt a ban again.
									</p>
								</div>
							</div>
						</div>

						<!-- Banlist sharing and community -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-share h2 text-primary"></i>
									</div>
									<h5>Ban list sharing</h5>
									<p class="text-muted mb-0">
										You can share your ban lists for others to use or assign one of the community managed ones to your
										own servers.
									</p>
								</div>
							</div>
						</div>

						<!-- Discord integration -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fab fa-discord h2 text-primary"></i>
									</div>
									<h5>Discord notifications</h5>
									<p class="text-muted mb-0">
										Communication is key. CFTools Cloud offers you to schedule real-time Discord notifications for
										events on your server.
									</p>
								</div>
							</div>
						</div>

						<!-- Player info -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-user-tag h2 text-primary"></i>
									</div>
									<h5>Player information</h5>
									<p class="text-muted mb-0">
										CFTools Cloud automatically converts anonymous players into CFTools Players. A profile system where
										all your data is stored, tracked. Easily accessible.
									</p>
								</div>
							</div>
						</div>

						<!-- API -->
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-code h2 text-primary"></i>
									</div>
									<h5>API</h5>
									<p class="text-muted mb-0">Using the CFTools API you can get all your information and have custom
										integrations.</p>
								</div>
							</div>
						</div>
						<!--
						<div class="col-sm-3 d-flex align-items-stretch">
							<div class="card border">
								<div class="card-body">
									<div class="mb-3">
										<i class="fas fa-plus h2 text-primary"></i>
									</div>
									<h5>More</h5>
									<p class="text-muted mb-0">
										CFTools Cloud offers various more features. Just check it out using our free plan.
									</p>
								</div>
							</div>
						</div>
						-->
					</div>
					<!-- end row -->
				</div>
				<!-- end container -->
			</section>
			<!-- Features end -->

			<!-- Roadmap start -->
			<!--
			<section class="section bg-white" id="roadmap">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="text-center mb-5">
								<div class="small-title">Timeline</div>
								<h4>Our Roadmap</h4>
							</div>
						</div>
					</div>


					<div class="row mt-4">
						<div class="col-lg-12">
							<div class="hori-timeline">
								<carousel
									ref="carousel"
									class="events navs-carousel owl-carousel"
									id="timeline-carousel"
									:perPageCustom="[[480, 2], [768, 4]]"
									:navigation-enabled="false"
									:pagination-enabled="false"
								>
									<slide>
										<div class="item event-list">
											<div>
												<div class="event-date">

													<h5 class="mb-4">Game support</h5>
												</div>
												<div class="event-down-icon">
													<i class="fas fa-calendar h3 text-primary down-arrow-icon"></i>
												</div>

												<div class="mt-3 px-3">
													<p
														class="text-muted"
													>Update for ArmA 3 and Rust support</p>
												</div>
											</div>
										</div>
									</slide>
									<slide>
										<div class="item event-list">
											<div>
												<div class="event-date">

													<h5 class="mb-4">Game support</h5>
												</div>
												<div class="event-down-icon">
													<i class="fas fa-calendar h3 text-primary down-arrow-icon"></i>
												</div>

												<div class="mt-3 px-3">
													<p
															class="text-muted"
													>Update for DayZ PS4 and XBox support</p>
												</div>
											</div>
										</div>
									</slide>
								</carousel>
								<div class="owl-nav">
									<button
										type="button"
										role="presentation"
										class="owl-prev"
										@click.prevent="prevSlide"
									>
										<i class="mdi mdi-chevron-left"></i>
									</button>
									<button
										type="button"
										role="presentation"
										class="owl-next"
										@click.prevent="nextSlide"
									>
										<i class="mdi mdi-chevron-right"></i>
									</button>
								</div>
							</div>
						</div>
					</div>

				</div>
			</section>
			-->
			<!-- Roadmap end -->

			<!-- Supported games -->
			<section class="section pt-0" id="games">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="text-center mb-5">
								<div class="small-title">Games</div>
								<h4>Games supported by CFTools Cloud</h4>
							</div>
						</div>
					</div>
					<!-- end row -->
					<div class="col-lg-12">
						<div class="row justify-content-center">
							<div class="col-lg-3 col-sm-6 col-md-6">
								<div class="card text-center team-box">
									<div class="overlay" style="display: none;">
										<div class="overlay-text text-danger">
											Temporarily unavailable for legal reasons
										</div>
									</div>
									<div class="card-body">
										<div>
											<img
													src="https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/apps/221100/3520d3db4e282585b24fe28178ca86f54874e3b5.jpg"
													alt/>
										</div>

										<div class="mt-3">
											<h5 class="mb-0">DayZ Standalone (PC)</h5>
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-sm-6 col-md-6">
								<div class="card text-center team-box">
									<div class="overlay" style="display: none;">
										<div class="overlay-text">
											<h5>Coming soon</h5>
										</div>
									</div>
									<div class="card-body">
										<div>
											<img
													src="https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/apps/107410/b49962441a01f1f80b180af1293608dddf7df6b0.jpg"
													alt/>
										</div>

										<div class="mt-3">
											<h5 class="mb-0">ArmA 3</h5> <!-- <small class="badge badge-primary text-black">Beta</small> -->
										</div>
									</div>
								</div>
							</div>

							<div class="col-lg-3 col-sm-6 col-md-6">
								<div class="card text-center team-box">
									<div class="overlay" style="display: none;">
										<div class="overlay-text">
											<h5>Coming soon</h5>
										</div>
									</div>
									<div class="card-body">
										<div>
											<img
													src="https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/apps/33900/9d4d576e4662870232eae35e50b041f93a9a6fa0.jpg"
													alt/>
										</div>

										<div class="mt-3">
											<h5 class="mb-0">ArmA 2</h5>
										</div>
									</div>
								</div>
							</div>

							<!--
							<div class="col-lg-3 col-sm-6 col-md-6">
								<div class="card text-center team-box">
									<div class="overlay" style="display: block;">
										<div class="overlay-text">
											<h5>Coming soon</h5>
										</div>
									</div>
									<div class="card-body">
										<div>
											<img src="https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/apps/221100/3520d3db4e282585b24fe28178ca86f54874e3b5.jpg" alt />
										</div>

										<div class="mt-3">
											<h5 class="mb-0">DayZ Standalone (Console)</h5>
										</div>
									</div>
								</div>
							</div>
							-->

							<!--
							<div class="col-lg-3 col-sm-6 col-md-6">
								<div class="card text-center team-box">
									<div class="overlay" style="display: block;">
										<div class="overlay-text">
											<h5>Coming soon</h5>
										</div>
									</div>
									<div class="card-body">
										<div>
											<img src="https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/apps/221100/3520d3db4e282585b24fe28178ca86f54874e3b5.jpg" alt />
										</div>

										<div class="mt-3">
											<h5 class="mb-0">DayZ Standalone (XBox)</h5>
										</div>
									</div>
								</div>
							</div>
							-->
						</div>
					</div>
					<div class="row">
						<div class="col-lg-12">
							<div class="text-center mb-2">
								<h6>Upcoming titles</h6>
							</div>
						</div>
						<div class="col-lg-12">
							<div class="row justify-content-center">
								<div class="col-lg-3 col-sm-6 col-md-6">
									<div class="card text-center team-box">
										<div class="overlay" style="display: block;">
											<div class="overlay-text">
												<h5>Coming soon</h5>
											</div>
										</div>
										<div class="card-body">
											<div>
												<img
														src="https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/apps/252490/ced8982cc46ce2b31cdb746f0abf61e9e8935913.jpg"
														alt/>
											</div>

											<div class="mt-3">
												<h5 class="mb-0">Rust</h5>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-sm-6 col-md-6">
									<div class="card text-center team-box">
										<div class="overlay" style="display: block;">
											<div class="overlay-text">
												<h5>Coming soon</h5>
											</div>
										</div>
										<div class="card-body">
											<div>
												<img
														src="https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/apps/686810/da1a34ca8923a4a64b5ee281bdfc9e043c5d8b19.jpg"
														alt/>
											</div>

											<div class="mt-3">
												<h5 class="mb-0">Hell Let Loose</h5>
											</div>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-sm-6 col-md-6">
									<div class="card text-center team-box">
										<div class="overlay" style="display: block;">
											<div class="overlay-text">
												<h5>Coming soon</h5>
											</div>
										</div>
										<div class="card-body">
											<div>
												<img
														src="https://cdn.cloudflare.steamstatic.com/steamcommunity/public/images/apps/393380/0a3d0e18d60cc7697562eb9865886aaad3b1730a.jpg"
														alt/>
											</div>

											<div class="mt-3">
												<h5 class="mb-0">Squad</h5>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<!-- end row -->
				</div>
				<!-- end container -->
			</section>
			<!-- Team end -->

			<!-- Pricing start -->
			<Subscription/>
			<!-- Pricing end -->
			<!-- Blog start -->
			<!--
			<section class="section bg-white" id="news">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="text-center mb-5">
								<div class="small-title">Blog</div>
								<h4>Latest News</h4>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-12 text-center">
							Currently no news
						</div>

					</div>

				</div>

			</section>
			-->
			<!-- Blog API
				<div class="col-xl-4 col-sm-6">
					<div class="blog-box mb-4 mb-xl-0">
						<div class="position-relative">
							<img
								src="asset"
								alt
								class="rounded img-fluid mx-auto d-block"
							/>
							<div class="badge badge-success blog-badge font-size-11">title</div>
						</div>

						<div class="mt-4 text-muted">
							<p class="mb-2">
								<i class="bx bx-calendar mr-1"></i> 04 Mar, 2020
							</p>
							<h5 class="mb-3">description1</h5>
							<p>description2</p>

							<div>
								<a href="#">Read more</a>
							</div>
						</div>
					</div>
				</div>
				-->
			<!-- Blog end -->

			<!-- Faqs start -->
			<section class="section" id="faqs">
				<div class="container">
					<div class="row">
						<div class="col-lg-12">
							<div class="text-center mb-5">
								<div class="small-title">FAQs</div>
								<h4>Frequently asked questions</h4>
							</div>
						</div>
					</div>
					<!-- end row -->
					<div class="row">
						<div class="col-lg-12">
							<div class="vertical-nav">
								<b-tabs pills vertical nav-wrapper-class="col-lg-2 col-sm-12 col-s" id="ignorecolauto">
									<b-tab active>
										<template v-slot:title>
											<i class="fas fa-question-circle nav-icon d-block mb-2"></i>
											<p class="font-weight-bold mb-0">General Questions</p>
										</template>
										<b-card-text>
											<div class="card">
												<div class="card-body">
													<div role="tablist">
														<b-card no-body class="custom-accordion shadow-none mb-3">
															<b-card-header header-tag="header" role="tab">
																<a
																		href="javascript: void(0);"
																		class="accordion-list"
																		v-b-toggle.faq-general-1
																>
																	<div class="h6 text-uppercase p-0 m-0 text-white">
																		RCon Edge Clusters
																	</div>
																	<i class="mdi mdi-minus accor-plus-icon"></i>
																</a>
															</b-card-header>
															<b-collapse
																	id="faq-general-1"
																	visible
																	accordion="faq-general"
																	role="tabpanel"
															>
																<b-card-body>
																	<p
																			class="mb-0"
																	>
																		Your game servers will be monitored reliabily and securly by one of our RCon edge clusters. We are present on 4 continents and always expanding.<br/>
																		<br/>
																		Our RCon edge clusters keep a connection to your servers 24/7, and will alert you about game server downtimes and more. Data from the RCon edge clusters is then transmitted for processing in our EU data warehouse, keeping your data secure and compliant all the way.<br/>
																		<br/>
																		All your sensitive data is encrypted in traffic and at rest.
																	</p>
																</b-card-body>
															</b-collapse>
														</b-card>

														<b-card no-body class="custom-accordion shadow-none mb-3">
															<b-card-header header-tag="header" role="tab">
																<a
																		href="javascript: void(0);"
																		class="accordion-list "
																		v-b-toggle.faq-general-2
																>
																	<div class="h6 text-uppercase p-0 m-0 text-white">
																		Regional Availability
																	</div>
																	<i class="mdi mdi-minus accor-plus-icon"></i>
																</a>
															</b-card-header>
															<b-collapse
																	id="faq-general-2"
																	accordion="faq-general"
																	role="tabpanel"
															>
																<b-card-body>
																	<p
																			class="mb-0"
																	>
																		We maintain our RCon edge clusters in following locations:
																		<ul>
																			<li>EU (Central)</li>
																			<li>NA (US)</li>
																			<li>NA (Canada)</li>
																			<li>AS (Singapore)</li>
																			<li>OC (Australia)</li>
																		</ul>

																		Regardless of your servers physical location, we will detect the fastest peering so you will never encounter RCon delays.
																	</p>
																</b-card-body>
															</b-collapse>
														</b-card>

														<b-card no-body class="custom-accordion shadow-none mb-3">
															<b-card-header header-tag="header" role="tab">
																<a
																		href="javascript: void(0);"
																		class="accordion-list "
																		v-b-toggle.faq-general-4
																>
																	<div class="h6 text-uppercase p-0 m-0 text-white">
																		Securing your data
																	</div>
																	<i class="mdi mdi-minus accor-plus-icon"></i>
																</a>
															</b-card-header>
															<b-collapse
																	id="faq-general-4"
																	accordion="faq-general"
																	role="tabpanel"
															>
																<b-card-body>
																	<p
																			class="mb-0"
																	>
																		All data is stored in our secure data warehouse located in EU, Germany.<br/>
																		<br/>
																		Everything sensitive, like your RCon passwords, is encrypted before being stored in our databases and only decrypted when needed.<br/>All our systems are monitored and audited regularly.
																	</p>
																</b-card-body>
															</b-collapse>
														</b-card>

														<b-card no-body class="custom-accordion shadow-none mb-3">
															<b-card-header header-tag="header" role="tab">
																<a
																		href="javascript: void(0);"
																		class="accordion-list "
																		v-b-toggle.faq-general-5
																>
																	<div class="h6 text-uppercase p-0 m-0 text-white">
																		Service levels
																	</div>
																	<i class="mdi mdi-minus accor-plus-icon"></i>
																</a>
															</b-card-header>
															<b-collapse
																	id="faq-general-5"
																	accordion="faq-general"
																	role="tabpanel"
															>
																<b-card-body>
																	<p
																			class="mb-0"
																	>
																		We at CFTools Cloud care about service and reliability. We guarantee an availability above 99%.
																	</p>
																</b-card-body>
															</b-collapse>
														</b-card>
														<b-card no-body class="custom-accordion shadow-none mb-3">
															<b-card-header header-tag="header" role="tab">
																<a
																		href="javascript: void(0);"
																		class="accordion-list "
																		v-b-toggle.faq-general-gdpr
																>
																	<div class="h6 text-uppercase p-0 m-0 text-white">
																		Privacy And Compliance
																	</div>
																	<i class="mdi mdi-minus accor-plus-icon"></i>
																</a>
															</b-card-header>
															<b-collapse
																	id="faq-general-gdpr"
																	accordion="faq-general"
																	role="tabpanel"
															>
																<b-card-body>
																	<p
																			class="mb-0"
																	>
																		CFTools Cloud is fully compliant with GDPR.	All your data is stored securely in our	secure data warehouse located in EU, Germany.<br/>
																		<br/>
																		We fully handle everything compliance related automatically for you, including data processing addendums and more.
																	</p>
																</b-card-body>
															</b-collapse>
														</b-card>
													</div>
												</div>
											</div>
										</b-card-text>
									</b-tab>
									<b-tab>
										<template v-slot:title>
											<i class="fas fa-shopping-cart nav-icon d-block mb-2"></i>
											<p class="font-weight-bold mb-0">Subscriptions</p>
										</template>
										<b-card-text>
											<div class="card">
												<div class="card-body">
													<div role="tablist">
														<!-- Free -->
														<b-card no-body class="custom-accordion shadow-none mb-3">
															<b-card-header header-tag="header" role="tab">
																<a
																		href="javascript: void(0);"
																		class="accordion-list"
																		v-b-toggle.faq-pay-1
																>
																	<div class="h6 text-uppercase p-0 m-0 text-white">
																		Free Plan
																	</div>
																	<i class="mdi mdi-minus accor-plus-icon"></i>
																</a>
															</b-card-header>
															<b-collapse visible id="faq-pay-1" accordion="faq-pay" role="tabpanel">
																<b-card-body>
																	<b-card-text>
																		<p
																				class="mb-0"
																		>
																			We got you covered! You can register and add one server to the platform totally free.<br/>
																			You can use it for as long as you want. Also, you will not be asked to add your billing details until you are ready to upgrade.
																		</p>
																	</b-card-text>
																</b-card-body>
															</b-collapse>
														</b-card>

														<b-card no-body class="custom-accordion shadow-none mb-3">
															<b-card-header header-tag="header" role="tab">
																<a
																		href="javascript: void(0);"
																		class="accordion-list"
																		v-b-toggle.faq-pay-3
																>
																	<div class="h6 text-uppercase p-0 m-0 text-white">
																		Payment methods
																	</div>
																	<i class="mdi mdi-minus accor-plus-icon"></i>
																</a>
															</b-card-header>
															<b-collapse id="faq-pay-3" accordion="faq-pay" role="tabpanel">
																<b-card-body>
																	<b-card-text>
																		<p
																				class="mb-0"
																		>
																			We accept PayPal, crypto currency and all major credit and debit cards. Your payment info is stored and processed securely by PayPal or Stripe, PCI Level 1 certified payments providers, and never touches our servers.
																		</p>
																	</b-card-text>
																</b-card-body>
															</b-collapse>
														</b-card>

														<b-card no-body class="custom-accordion shadow-none mb-3">
															<b-card-header header-tag="header" role="tab">
																<a
																		href="javascript: void(0);"
																		class="accordion-list"
																		v-b-toggle.faq-pay-cancel
																>
																	<div class="h6 text-uppercase p-0 m-0 text-white">
																		Cancellation
																	</div>
																	<i class="mdi mdi-minus accor-plus-icon"></i>
																</a>
															</b-card-header>
															<b-collapse id="faq-pay-cancel" accordion="faq-pay" role="tabpanel">
																<b-card-body>
																	<b-card-text>
																		<p
																				class="mb-0"
																		>
																			You can downgrade or cancel your subscription anytime. When you cancel, you will still have access to the plan's features for the time you have already paid for.
																		</p>
																	</b-card-text>
																</b-card-body>
															</b-collapse>
														</b-card>
													</div>
												</div>
											</div>
										</b-card-text>
									</b-tab>
								</b-tabs>
							</div>
						</div>
					</div>
				</div>
			</section>
			<!-- end Faqs start -->

			<!-- Footer start -->
			<Footer :isHomepage="true"/>
			<!-- Footer end -->
		</div>
	</div>
</template>